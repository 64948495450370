import React, { ReactElement, useEffect, useState } from 'react'
import { Row, Col, Typography } from 'antd'
import axios, { CancelToken } from 'axios'
import Lottie from 'react-lottie'
import { connect, useDispatch } from 'react-redux'
import { debounce } from 'lodash'

import animationData from '../../assets/breath-loader-2.json'
import './VerificationPage.styles.scss'
import { IVerifyPayment, IVerifyPaymentOption } from '../../models/verify-payment.model'
import { verifyPaymentAction } from '../../redux/actions/verify-payment'
import { ConfirmationPage } from '../ConfirmationPage'
import { useAxios } from '../../hooks'

interface IVerificationProps {
  reference: string
  verifyPaymentDetails?: IVerifyPayment
  setStepLevel: (number: number) => void
  cancel: () => null | void
  makeNewPayment: () => void
  onLogout: () => Promise<void>
  isMobileOrTablet: boolean
}

const VerificationPage: React.FC<IVerificationProps> = ({
  reference,
  verifyPaymentDetails,
  setStepLevel,
  cancel,
  makeNewPayment,
  onLogout,
  isMobileOrTablet,
}: IVerificationProps): ReactElement => {
  const dispatch = useDispatch()
  const axiosInstance = useAxios()

  const getVerifyPayment = (
    options: IVerifyPaymentOption,
    source: { token: CancelToken; cancel: () => void },
  ) => verifyPaymentAction(axiosInstance, options, source)(dispatch)

  const { Text } = Typography
  const [isRetrieveStatus, setisRetrieveStatus] = useState<boolean>(true)

  const status = verifyPaymentDetails?.status as string

  useEffect(() => {
    const cancelToken = axios.CancelToken
    const source = cancelToken.source()
    const options = {
      reference,
      paymentProcessor: 'flutterwave',
    }

    const handleVerifyPayment = debounce(() => {
      if (!['completed', 'failed'].includes(status)) {
        try {
          getVerifyPayment(options, undefined as any)
        } catch (err) {
          setisRetrieveStatus(false)
        }
      } else {
        setisRetrieveStatus(false)
      }
    }, 1000)

    handleVerifyPayment()

    return () => {
      source.cancel()
      handleVerifyPayment.cancel()
    }
  }, [status, isRetrieveStatus])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  if (isRetrieveStatus || status === 'pending') {
    return (
      <Row style={{ marginLeft: '10%' }}>
        <Col span={22}>
          {isMobileOrTablet ? (
            <div>
              <Lottie options={defaultOptions} height={250} width={250} />
            </div>
          ) : (
            <Lottie options={defaultOptions} height={450} width={450} />
          )}

          <Text className="page-text">Verifying your transaction . . .</Text>
        </Col>
      </Row>
    )
  }

  return (
    <Row>
      {(!isRetrieveStatus || status === 'completed' || status === 'failed') && (
        <ConfirmationPage
          status={status}
          setStepLevel={setStepLevel}
          cancel={cancel}
          makeNewPayment={makeNewPayment}
          onLogout={onLogout}
        />
      )}
    </Row>
  )
}

const mapStateToProps = ({
  verifyPaymentReducer,
}: {
  verifyPaymentReducer: { verifyPaymentDetails: IVerifyPayment; isLoading: boolean }
}) => {
  const { verifyPaymentDetails, isLoading: verifyPaymentLoading } = verifyPaymentReducer

  return {
    verifyPaymentDetails,
    verifyPaymentLoading,
  }
}

export default connect(mapStateToProps)(VerificationPage)
