import React, { ReactElement, useEffect } from 'react'
import { Typography, Button } from 'antd'
import { ReactComponent as HoorayImage } from '../../assets/hooray.svg'
import CustomButton from '../../components/CustomButton'

import './ConfirmationPage.styles.scss'
import { trackAmplitudeEventProperty } from '../../utils/amplitude-event.util'

interface IConfirmationComponent {
  cancel: () => void
  onLogout: () => Promise<void>
  status: string
}

const SuccessComponent = ({ cancel, onLogout, status }: IConfirmationComponent): ReactElement => {
  const { Text } = Typography

  useEffect(() => {
    trackAmplitudeEventProperty('viewed payment_verification')
    trackAmplitudeEventProperty('viewed payment_success')
  }, [status])

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <HoorayImage data-testid="hooray-image" />
      <Text className="confirmation-text bolder-text">Hooray!</Text>
      <Text className="header-text-success">
        You’ve completed a payment. <br /> A confirmation has been sent to your email.
      </Text>
      <div className="button">
        <CustomButton bgColor="purple" color="white" text="Make another payment" onClick={cancel} />
      </div>
      <Button type="link" onClick={onLogout} className="logout-button">
        Logout
      </Button>
    </div>
  )
}

export default SuccessComponent
