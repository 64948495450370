import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import axios, { CancelToken } from 'axios'
import { useStateIfMounted } from 'use-state-if-mounted'
import { Row, Col, Alert, Tabs } from 'antd'
import moment from 'moment-timezone'

import Header from '../../components/Header'
import { IUser } from '../../models/user.model'
import { ModalContentType } from '../../models/modal.model'
import { verifyPayment } from '../../api/verify-payment.api'
import {
  calculateLineItemAction,
  getUserAction,
  setStepLevel as setStepLevelFnc,
  setPaymentSetupStep as setPaymentSetupStepFnc,
  setProcessingFee as setProcessingFeeFnc,
  setTransactionStatus as setTransactionStatusFnc,
  setHowToPay as setHowToPayFnc,
  setEnergyCreditsCheckBox as setEnergyCreditsCheckBoxFnc,
  setCommunityFeeCheckBox as setCommunityFeeCheckBoxFnc,
  setEnergyCreditFee as setEnergyCreditFeeFnc,
  setCommunityFee as setCommunityFeeFnc,
} from '../../redux/actions'
import { GET_USER_TYPE, INITIALIZE_PAYMENT_TYPE, CALCULATE_LINE_ITEM_TYPE } from '../../redux/types'
import PayeeDetailsPaymentAmount from './PayeeDetailsPaymentAmount'
import ModalContent from './ModalContent'
import CustomModal from '../../components/CustomModal'
import ProgressBar from '../../components/ProgressBar'
import { FLUTTERWAVE_LIMIT, HowToPayTypes } from '../../utils/constant'

import './Dashboard.styles.scss'
import {
  ICalculateLineItemOptions,
  IFacilityUnit,
  IResidentUserRole,
} from '../../models/facility-unit.model'
import { KeyStorage } from '../../models'
import { localStorageService } from '../../services'
import { AmountInputType, ILineItem } from '../../models/line-item.model'
import { formatMoney } from '../../utils'

import { IInitializePayment, IInitializePaymentOption } from '../../models/initialize-payment.model'
import VerificationPage from '../VerificationPage'
import { trackAmplitudeEventProperty } from '../../utils/amplitude-event.util'
import { FailureComponent } from '../ConfirmationPage'
import CrashPage from '../CrashPage'
import { useNetwork, useMediaQueries, useAxios, useAuth0 } from '../../hooks'
import { TransactionStatus } from '../../models/verify-payment.model'
import CancelTransaction from '../../components/CancelTransaction/CancelTransaction'
import PaymentPortalIFrame from './PaymentPortalIframe'
import { PaymentStepLevel, PaymentSetupStep } from './Dashboard.metadata'
import PaymentSetup from '../PaymentSetup/PaymentSetup'
import PaymentProcessing from '../PaymentProcessing/PaymentProcessing'
import PaymentConfirmation from '../PaymentConfirmation/PaymentConfirmation'
import { resetPaymentSetupAction } from '../../redux/actions/global-states'
import { facilityUnitAction } from '../../redux/actions/get-facility-unit'
import { initializePaymentAction } from '../../redux/actions/initialize-payment'
import useStsMeter from '../../hooks/useStsMeter'
import { EnvContext } from '../../utils/context'

const { TabPane } = Tabs
interface IDashboardProps {
  // getUserInfo: () => Promise<void>
  setProcessingFee: any
  setTransactionStatus: any
  setHowToPay: any
  setEnergyCreditsCheckBox: any
  setCommunityFeeCheckBox: any
  setEnergyCreditFee: any
  setCommunityFee: any
  setStepLevel: any
  setPaymentSetupStep: any
  lineItems: any
  facilityUnitLoading: boolean
  facilityUnit: any
  initializePaymentDetails: IInitializePayment
  userDataLoading: boolean
  stepLevel: number
  howToPay: string
  paymentSetupStep: number
  communityFeeGlobal: number
  energyCreditFee: number
  processingFee: number
  lineItemLoading: boolean
  errorFromReducers: any
  showPaymentModalFromTest?: boolean
  resetPaymentSetup: any
  communityFeeCheckBoxState: boolean
  energyCreditsCheckBoxState: boolean
}

const Dashboard = ({
  // getUserInfo,
  setProcessingFee,
  setTransactionStatus,
  setHowToPay,
  setEnergyCreditsCheckBox,
  setCommunityFeeCheckBox,
  setEnergyCreditFee,
  setCommunityFee,
  setStepLevel,
  setPaymentSetupStep,
  lineItems,
  facilityUnitLoading,
  facilityUnit,
  initializePaymentDetails,
  userDataLoading,
  stepLevel,
  howToPay,
  paymentSetupStep,
  communityFeeGlobal,
  energyCreditFee,
  processingFee,
  lineItemLoading,
  errorFromReducers,
  communityFeeCheckBoxState,
  energyCreditsCheckBoxState,
  resetPaymentSetup,

  showPaymentModalFromTest,
}: IDashboardProps): ReactElement => {
  const auth0 = useAuth0()
  const axiosInstance = useAxios()
  const dispatch = useDispatch()

  const getUserInfo = () => getUserAction(axiosInstance)(dispatch)
  const calculateLineItem = (
    options: ICalculateLineItemOptions,
    source: { token: CancelToken; cancel: () => void },
  ) => calculateLineItemAction(axiosInstance, options, source)(dispatch)
  const getFacilityUnit = () => facilityUnitAction(axiosInstance)(dispatch)
  const getInitializePayment = (options: IInitializePaymentOption) =>
    initializePaymentAction(axiosInstance, options)(dispatch)

  const isSTSMeter = useStsMeter()
  const [userDetails, setUserDetails] = useStateIfMounted({})
  const [toContinue, setToContinue] = useState(false)
  const [currentFacilityUnitResidentUserRole, setCurrentFacilityUnit] = useState<any>({})
  const [currentMeterNumber, setCurrentMeterNumber] = useState<string>('')
  const [maxPaymentAlert, setMaxPaymentAlert] = useState<boolean>(false)
  const [showPaymentPortal, setShowPaymentPortal] = useState<boolean>(false)
  const [showVerifyPayment, setShowVerifyPayment] = useState<boolean>(false)
  const [isCalculating, setIsCalculating] = useState<boolean>(false)
  const [timeLeft, setTimeLeft] = useState(0)
  const [paymentStatus, setPaymentStatus] = useState('')
  const [inputAmount, setInputAmount] = useState<AmountInputType>({
    facilityFee: {
      value: '₦ 0.00',
      label: '₦ 0.00',
      key: 'None ₦ 0.00',
    },
    energyFee: 0.0,
  })
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState<ModalContentType>({
    title: '',
    body: [],
  })

  const isOnline = useNetwork()
  const { isMobileOrTablet } = useMediaQueries()

  const { GET_USER_SUCCESS } = GET_USER_TYPE
  const { INITIALIZE_PAYMENT_SUCCESS, INITIALIZE_PAYMENT_FAILURE } = INITIALIZE_PAYMENT_TYPE
  const { CALCULATE_LINE_ITEM_FAILURE } = CALCULATE_LINE_ITEM_TYPE

  const currentMeterState = facilityUnit?.currentMeterState
  const chargedFeesDebitBalanceCents = currentMeterState?.chargedFeesDebitBalanceCents || 0
  const pastAmountDue = currentMeterState?.chargedOverdueAmountCents
  const startingEnergyCreditCents = currentMeterState?.usageCreditBalanceCents

  const sumTotalWithFeeIncent = () =>
    Math.round((energyCreditFee + communityFeeGlobal) * 100 + processingFee)

  const sumTotalWithoutFeeIncent = () => Math.round((energyCreditFee + communityFeeGlobal) * 100)

  const totalAmountDue = Math.max(0, chargedFeesDebitBalanceCents)
  const { error, type: errorType } = errorFromReducers

  const [isProcessing, setIsProcessing] = useState(false)

  const communityFeeOptions = [
    totalAmountDue && {
      value: `Total Amount Due ₦ ${totalAmountDue / 100}`,
      label: `Total Amount Due ₦ ${formatMoney(totalAmountDue / 100)}`,
    },
    pastAmountDue && {
      value: `Past Amount Due ₦ ${pastAmountDue / 100}`,
      label: `Past Amount Due ₦ ${formatMoney(pastAmountDue / 100)}`,
    },
    {
      value: 0,
      label: 'None ₦ 0.00',
    },
  ]

  useEffect(() => {
    const handleUserInfo = () => {
      getUserInfo().then(async (response: any) => {
        if (response?.type === GET_USER_SUCCESS) {
          setUserDetails(response.payload)
          getFacilityUnit()
        }
      })
    }
    handleUserInfo()
  }, [])

  const getEnergyCreditAndCommunityFeesInCents = () => {
    const { facilityFee, energyFee = 0 } = inputAmount
    const communityFee = Number(facilityFee.value ? facilityFee.value.split('₦')[1] : 0)
    const energyCredits = Number(energyFee) < 0 ? 0 : Number(energyFee)

    return {
      energyCreditsInCents: energyCredits * 100,
      communityFeeInCents: communityFee * 100,
    }
  }

  const makeRequest = (source?: { token: CancelToken }): void => {
    const { energyCreditsInCents, communityFeeInCents } = getEnergyCreditAndCommunityFeesInCents()
    const subTotal = Math.round(energyCreditsInCents + communityFeeInCents)
    setIsCalculating(true)
    getFacilityUnit()

    calculateLineItem(
      { baseAmountCents: subTotal, paymentProcessor: 'flutterwave' },
      source as any,
    ).then((response: any) => {
      setIsCalculating(false)
      if (!response) {
        setShowModal(true)
      } else {
        setShowModal(false)
      }
    })

    const sumAmount = lineItems?.totalAmountCents

    const maxPayment = lineItems?.totalAmountCents > FLUTTERWAVE_LIMIT

    setToContinue(!maxPayment)

    return sumAmount > FLUTTERWAVE_LIMIT ? setMaxPaymentAlert(true) : setMaxPaymentAlert(false)
    // communityFeeInCents + energyCreditsInCents >= 10000 || showPaymentModalFromTest
    //   ? setToContinue(true)
    //   : setToContinue(false)
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken
    const source = cancelToken.source()

    makeRequest(source)

    return () => {
      source.cancel()
    }
  }, [inputAmount])

  const handleMeterChange = (value: string, residentUserRoles: IResidentUserRole[]): void => {
    setCurrentMeterNumber(value)
    const currentResidentUserRole = residentUserRoles?.find(
      (residentUserRole: IResidentUserRole) =>
        residentUserRole?.facilityUnit?.meter?.displayedId === value,
    )
    const activeFacilityUnitId = currentResidentUserRole?.facilityUnit?.id || ''
    setCurrentFacilityUnit(currentResidentUserRole)
    localStorageService().store(KeyStorage.activeFacilityUnitUserRole, currentResidentUserRole)
    localStorageService().store(KeyStorage.activeFacilityUnitId, activeFacilityUnitId)
    setInputAmount({
      ...inputAmount,
      facilityFee: { value: '₦ 0.00', label: '₦ 0.00', key: 'None ₦ 0.00' },
    })
    makeRequest()
  }

  const handleCommunityFeeChange = (option: {
    value: string
    label: string
    key: string
  }): void => {
    const { value, label, key } = option

    setInputAmount({
      ...inputAmount,
      facilityFee: { label, value, key },
    })
  }

  const resetTransaction = (): void => {
    // TODO: delegate actions
    setStepLevel(0)
    setPaymentSetupStep(0)
    setProcessingFee(0)
    setTransactionStatus('')
    setHowToPay('')
    setEnergyCreditsCheckBox(false)
    setCommunityFeeCheckBox(false)
    setEnergyCreditFee(0)
    setCommunityFee(0)

    setIsProcessing(false)
    setShowPaymentPortal(false)
    setInputAmount({
      ...inputAmount,
      facilityFee: { value: '₦ 0.00', label: '₦ 0.00', key: 'None ₦ 0.00' },
      energyFee: 0.0,
    })
  }

  const cancel = (): void => {
    trackAmplitudeEventProperty('clicked cancel_button')
    resetTransaction()
  }

  const handleFlutterwaveCancelAction = (): void => {
    resetTransaction()
  }

  const maxPayment = lineItems?.totalAmountCents > FLUTTERWAVE_LIMIT

  let disableProceedPayment = false
  if (isCalculating === false) {
    disableProceedPayment = !toContinue || maxPayment
  }

  const handleVerifyPayment = async (reference: string): Promise<TransactionStatus> => {
    return new Promise((resolve) => {
      const startTime = new Date().getTime()
      const maxWait = 31 * 60 * 1000

      const verifyInterval = setInterval(async () => {
        const verifyOptions = {
          reference,
          paymentProcessor: 'flutterwave',
        }
        try {
          const cancelToken = axios.CancelToken
          const source = cancelToken.source()
          const response = await verifyPayment(axiosInstance)(verifyOptions, source)

          const status = response?.status
          if ([TransactionStatus.failed, TransactionStatus.completed].includes(status)) {
            clearInterval(verifyInterval)
            return resolve(status)
          }

          if (new Date().getTime() > startTime + maxWait) {
            clearInterval(verifyInterval)
            return resolve(TransactionStatus.failed)
          }
        } catch (err) {
          clearInterval(verifyInterval)
          return 'failed'
        }
      }, 2000)
    })
  }

  useEffect(() => {
    if (['completed', 'failed', 'pending', 'success'].includes(paymentStatus)) {
      setShowPaymentPortal(false)
      setShowVerifyPayment(true)
    }
  }, [paymentStatus])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [stepLevel, paymentSetupStep])

  const env = useContext(EnvContext)

  const handlePaymentProcessing = () => {
    const { energyCreditsInCents, communityFeeInCents } = getEnergyCreditAndCommunityFeesInCents()

    trackAmplitudeEventProperty(`initialized payment with ${JSON.stringify(lineItems)}`)

    const options = {
      usageBaseAmountCents: energyCreditFee * 100,
      feeBaseAmountCents: communityFeeGlobal * 100,
      paymentProcessor: 'flutterwave',
      callbackUrl: env.FLUTTERWAVE_CALLBACK_URL,
      checkoutClient: 'webPortal',
    }

    if (howToPay === 'new-payment' && (energyCreditFee || communityFeeGlobal)) {
      setIsProcessing(true)
      getInitializePayment(options).then(async (response: any) => {
        if (response?.type === INITIALIZE_PAYMENT_SUCCESS) {
          setShowPaymentPortal(true)
          setStepLevel(2)
          const reference = response?.payload?.reference

          if (reference) {
            const status = await handleVerifyPayment(reference)

            setPaymentStatus(status)
            setIsProcessing(false)
          }
        }
      })
    }
  }

  const handleNextPaymentSetupStep = () => {
    if (paymentSetupStep >= PaymentSetupStep.PaymentReview) {
      setStepLevel(PaymentStepLevel.PaymentProcessing)
      setPaymentSetupStep(PaymentSetupStep.PaymentReview)

      handlePaymentProcessing()
      return
    }
    if (isSTSMeter) {
      setHowToPay('new-payment')
      setPaymentSetupStep(2)
      return
    }
    setPaymentSetupStep(paymentSetupStep + 1)
  }

  const handleInitializePayment = () => {
    switch (stepLevel) {
      case PaymentStepLevel.PayeeDetails:
        setStepLevel(PaymentStepLevel.PaymentSetup)
        break
      case PaymentStepLevel.PaymentSetup:
        handleNextPaymentSetupStep()
        break
      case PaymentStepLevel.PaymentProcessing:
        setStepLevel(PaymentStepLevel.Confirmation)
        break
      case PaymentStepLevel.Confirmation:
        setStepLevel(PaymentStepLevel.Confirmation)
        break
      default:
        break
    }
  }

  const calculateTimeLeft = () => {
    const currentTime = moment()
    const expiredAtValue = initializePaymentDetails.expiresAt || currentTime.toISOString()
    const expiredAt = moment(expiredAtValue).add(5, 'minutes')
    const durationSubtract = expiredAt.diff(currentTime)

    return durationSubtract
  }

  const clearTimerInterval = (timerInterval: NodeJS.Timeout) => {
    if (timeLeft !== 0 && timeLeft <= 1) {
      clearTimeout(timerInterval)
    }
  }

  useEffect(() => {
    // start timer only when processing payment (payment portal is active)
    if (showPaymentPortal) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)

      clearTimerInterval(timer)

      return () => clearTimeout(timer)
    }
  }, [showPaymentPortal, timeLeft])

  const makeNewPayment = () => {
    window.location.reload()
  }

  const onLogout = async () => {
    trackAmplitudeEventProperty('clicked confirmation_page_logout_button')
    localStorageService().clear()
    await auth0.logout()
  }

  const handleSetupPaymentStepGoBack = () => {
    if (isSTSMeter && paymentSetupStep === 2) {
      setHowToPay('new-payment')
      setPaymentSetupStep(0)
      return
    }

    if (paymentSetupStep <= PaymentSetupStep.WhatToPayFor) {
      setStepLevel(stepLevel - 1)
      setPaymentSetupStep(PaymentSetupStep.WhatToPayFor)
      return
    }

    if (paymentSetupStep <= PaymentSetupStep.HowToPay) {
      disableProceedPayment = false
    }
    setPaymentSetupStep(paymentSetupStep - 1)
  }

  const handleGoBack = (currentStepLevel: number) => {
    // handle go back differently for payment setup stage
    if (currentStepLevel === PaymentStepLevel.PaymentSetup) {
      handleSetupPaymentStepGoBack()
      return
    }

    if (currentStepLevel === PaymentStepLevel.PaymentProcessing) {
      setShowPaymentPortal(false)
      setIsProcessing(false)
    }

    setStepLevel(stepLevel - 1)
  }

  if (![CALCULATE_LINE_ITEM_FAILURE, INITIALIZE_PAYMENT_FAILURE].includes(errorType)) {
    if (
      error?.message?.includes('500') ||
      (error?.status && String(error?.status)?.match(/^5\d{2}$/))
    ) {
      return <CrashPage status={errorFromReducers?.status || 500} />
    }
  }

  const getWhenToDisableContinue = () =>
    PaymentStepLevel.PaymentProcessing === stepLevel ||
    (disableProceedPayment &&
      HowToPayTypes.NewPayment === howToPay &&
      PaymentSetupStep.PaymentReview === paymentSetupStep) ||
    sumTotalWithoutFeeIncent() < 0 ||
    (sumTotalWithoutFeeIncent() > FLUTTERWAVE_LIMIT &&
      energyCreditsCheckBoxState &&
      stepLevel !== PaymentStepLevel.PayeeDetails) ||
    (sumTotalWithoutFeeIncent() === 0 && stepLevel === PaymentStepLevel.PaymentSetup) ||
    (!howToPay && PaymentSetupStep.HowToPay === paymentSetupStep) ||
    (startingEnergyCreditCents - sumTotalWithFeeIncent() < 0 &&
      communityFeeCheckBoxState &&
      HowToPayTypes.EnergyCredit === howToPay &&
      PaymentSetupStep.PaymentReview === paymentSetupStep)

  return (
    <>
      <Header />
      {[CALCULATE_LINE_ITEM_FAILURE, INITIALIZE_PAYMENT_FAILURE].includes(errorType) &&
        (error?.message?.includes('500') ||
          (error?.status && String(error?.status)?.match(/^5\d{2}$/))) && (
          <CustomModal
            showModal={showModal}
            onOk={() => {
              setShowModal(false)
            }}
            onCancel={() => {
              setShowModal(false)
            }}
            style={
              !isMobileOrTablet
                ? {
                    marginLeft: '30%',
                    borderRadius: '8px',
                    padding: '24px 5px 50px',
                    marginTop: '5%',
                  }
                : {
                    marginLeft: '5%',
                  }
            }
          >
            <ModalContent title={modalContent.title} body={modalContent.body} isError />
          </CustomModal>
        )}

      <Row className={!isMobileOrTablet ? 'dashboard-div-desktop' : 'dashboard-div-mobile'}>
        {!isOnline && (
          <Alert
            showIcon={false}
            type="error"
            message="No internet connection. Please ensure you are connected to the internet to complete this transaction."
            banner
            className="alert-error-div"
          />
        )}
        {isMobileOrTablet &&
          !showVerifyPayment &&
          !(
            stepLevel === PaymentStepLevel.Confirmation && howToPay === HowToPayTypes.EnergyCredit
          ) && (
            <CancelTransaction
              showGoBack={stepLevel !== PaymentStepLevel.PayeeDetails}
              goBack={() => handleGoBack(stepLevel)}
              cancel={stepLevel !== PaymentStepLevel.PayeeDetails ? cancel : null}
              toContinue
              isBtnLoading={isProcessing}
              showPaymentPortal={showPaymentPortal}
              handleInitializePayment={handleInitializePayment}
              disableProceedPayment={getWhenToDisableContinue()}
            />
          )}

        <Col span={isMobileOrTablet ? 24 : 19} style={{ paddingBottom: '4rem' }}>
          {(process.env.NODE_ENV === 'test'
            ? showPaymentModalFromTest
            : showPaymentPortal || timeLeft <= 0) && initializePaymentDetails?.authorizationUrl ? (
            timeLeft !== 0 && timeLeft <= 1 ? (
              <FailureComponent
                cancel={() => {
                  trackAmplitudeEventProperty('clicked timeout_make_new_payment_button')
                  return makeNewPayment()
                }}
                onLogout={onLogout}
                textBody="This transaction timed out and was not completed. Please try again. "
                tokenGenerationFailed={false}
              />
            ) : (
              <PaymentPortalIFrame
                initializePaymentDetails={initializePaymentDetails}
                setPaymentStatus={setPaymentStatus}
                handleFlutterwaveCancelAction={handleFlutterwaveCancelAction}
              />
            )
          ) : showVerifyPayment ? (
            <VerificationPage
              reference={initializePaymentDetails?.reference}
              setStepLevel={setStepLevel}
              cancel={cancel}
              makeNewPayment={makeNewPayment}
              onLogout={onLogout}
              isMobileOrTablet={isMobileOrTablet}
            />
          ) : (
            <Tabs defaultActiveKey="0" activeKey={`${stepLevel}`}>
              <TabPane tab="Payee Details" key={PaymentStepLevel.PayeeDetails}>
                <PayeeDetailsPaymentAmount
                  userData={userDetails}
                  handleMeterChange={handleMeterChange}
                  currentFacilityUnitResidentUserRole={currentFacilityUnitResidentUserRole}
                  currentMeterNumber={currentMeterNumber}
                  setCurrentFacilityUnit={setCurrentFacilityUnit}
                  setCurrentMeterNumber={setCurrentMeterNumber}
                />
              </TabPane>
              <TabPane tab="Payment Set-Up" key={PaymentStepLevel.PaymentSetup}>
                <PaymentSetup />
              </TabPane>
              <TabPane tab="Payment Processing" key={PaymentStepLevel.PaymentProcessing}>
                <PaymentProcessing />
              </TabPane>
              <TabPane tab="Confirmation" key={PaymentStepLevel.Confirmation}>
                <PaymentConfirmation />
              </TabPane>
            </Tabs>
          )}
        </Col>
        <Col span={isMobileOrTablet ? 24 : 5} className="progress-div">
          <ProgressBar
            stepLevel={stepLevel}
            showPaymentPortal={showPaymentPortal}
            description={
              timeLeft <= 0
                ? ''
                : `This transaction will timeout in ${moment
                    .utc(timeLeft)
                    .format('mm:ss')} minutes . . .`
            }
            isMobileOrTablet={isMobileOrTablet}
          />
          {!isMobileOrTablet &&
            !showVerifyPayment &&
            !(
              stepLevel === PaymentStepLevel.Confirmation && howToPay === HowToPayTypes.EnergyCredit
            ) && (
              <CancelTransaction
                showGoBack={stepLevel !== PaymentStepLevel.PayeeDetails}
                goBack={() => handleGoBack(stepLevel)}
                cancel={stepLevel !== PaymentStepLevel.PayeeDetails ? cancel : null}
                showPaymentPortal={showPaymentPortal}
                toContinue
                isBtnLoading={isProcessing}
                handleInitializePayment={handleInitializePayment}
                disableProceedPayment={getWhenToDisableContinue()}
              />
            )}
        </Col>
      </Row>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  setStepLevel: (setStep: number) => dispatch(setStepLevelFnc(setStep)),
  setPaymentSetupStep: (paymentSetupStep: number) =>
    dispatch(setPaymentSetupStepFnc(paymentSetupStep)),
  setProcessingFee: (processingFee: number) => dispatch(setProcessingFeeFnc(processingFee)),
  setTransactionStatus: (transactionStatus: string) =>
    dispatch(setTransactionStatusFnc(transactionStatus)),
  setHowToPay: (howToPay: string) => dispatch(setHowToPayFnc(howToPay)),
  setEnergyCreditsCheckBox: (check: boolean) => dispatch(setEnergyCreditsCheckBoxFnc(check)),
  setCommunityFeeCheckBox: (check: boolean) => dispatch(setCommunityFeeCheckBoxFnc(check)),
  setEnergyCreditFee: (energyCreditFee: number) => dispatch(setEnergyCreditFeeFnc(energyCreditFee)),
  setCommunityFee: (communityFee: number) => dispatch(setCommunityFeeFnc(communityFee)),
  resetPaymentSetup: () => dispatch(resetPaymentSetupAction()),
})

const mapStateToProps = ({
  getUserReducer,
  paymentValuesReducer,
  setStepReducer,
  calculateLineItemReducer,
  getFacilityUnitReducer,
  initializePaymentReducer,
  errorReducer,
}: {
  getUserReducer: { userDetails: IUser; isLoading: boolean }
  setStepReducer: {
    stepLevel: number
    paymentSetupStep: number
    howToPay: string
    communityFeeCheckBoxState: boolean
    energyCreditsCheckBoxState: boolean
  }
  paymentValuesReducer: { communityFee: number; energyCreditFee: number; processingFee: number }
  calculateLineItemReducer: { lineItems: ILineItem; isLoading: boolean }
  getFacilityUnitReducer: { facilityUnit: IFacilityUnit; isLoading: boolean }
  initializePaymentReducer: { initializePaymentDetails: IInitializePayment; isLoading: boolean }
  errorReducer: { error: any; type: string }
}) => {
  const { userDetails, isLoading: userDataLoading } = getUserReducer
  const {
    stepLevel,
    paymentSetupStep,
    howToPay,
    communityFeeCheckBoxState,
    energyCreditsCheckBoxState,
  } = setStepReducer
  const { communityFee: communityFeeGlobal, energyCreditFee, processingFee } = paymentValuesReducer
  const { lineItems, isLoading: lineItemLoading } = calculateLineItemReducer
  const { facilityUnit, isLoading: facilityUnitLoading } = getFacilityUnitReducer
  const { initializePaymentDetails, isLoading: initializePaymentLoading } = initializePaymentReducer
  const errorFromReducers = errorReducer

  return {
    userDetails,
    stepLevel,
    howToPay,
    communityFeeCheckBoxState,
    energyCreditsCheckBoxState,
    paymentSetupStep,
    communityFeeGlobal,
    energyCreditFee,
    processingFee,
    lineItems,
    facilityUnit,
    facilityUnitLoading,
    initializePaymentDetails,
    initializePaymentLoading,
    userDataLoading,
    lineItemLoading,
    errorReducer,
    errorFromReducers,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
