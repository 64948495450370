import React, { ReactElement, useEffect } from 'react'
import { Row, Col } from 'antd'
import SuccessComponent from './SuccessComponent'
import FailureComponent from './FailureComponent'
import './ConfirmationPage.styles.scss'
import useStsMeter from '../../hooks/useStsMeter'

interface IConfirmation {
  status: string
  setStepLevel: (number: number) => void
  cancel: () => void
  makeNewPayment: () => void
  onLogout: () => Promise<void>
}

const ConfirmationPage = ({
  status,
  setStepLevel,
  cancel,
  makeNewPayment,
  onLogout,
}: IConfirmation): ReactElement => {
  const isSTSMeter = useStsMeter()

  useEffect(() => setStepLevel(3))

  const getScreen = () => {
    if (status === 'completed') {
      return (
        <SuccessComponent cancel={() => makeNewPayment()} onLogout={onLogout} status={status} />
      )
    }

    if (isSTSMeter) {
      // TODO: include check for failed token generation
      return (
        <FailureComponent
          cancel={() => makeNewPayment()}
          onLogout={onLogout}
          status={status}
          tokenGenerationFailed
          textBody=""
        />
      )
    }

    if (status === 'failed') {
      return (
        <FailureComponent
          tokenGenerationFailed={false}
          cancel={() => makeNewPayment()}
          onLogout={onLogout}
          status={status}
          textBody="An error occured with your transaction. Please try again. Refer to the Help/FAQ
          article for tips on payments. Contact your property’s facility/power manager for further
          questions or comments."
        />
      )
    }
  }
  return (
    <Row className="confirmation-div">
      <Col>{getScreen()}</Col>
    </Row>
  )
}

export default ConfirmationPage
